import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import QuemSomos from '../pages/QuemSomos';
import NossaFrota from '../pages/NossaFrota';
import Duvidas from '../pages/Duvidas';
import Politicas from '../pages/Politicas';

function Rotas() {
   return (
      <Router>
         <Routes>
            <Route path="/" exact element={<Home/>} />
            <Route path="/quem-somos" exact element={<QuemSomos/>} />
            <Route path="/nossa-frota" exact element={<NossaFrota/>} />
            <Route path="/duvidas" exact element={<Duvidas/>} />
            <Route path="/politica-de-privacidade" exact element={<Politicas/>} />
         </Routes>
      </Router>
   );
}

export default Rotas;