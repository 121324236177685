import React from 'react';
import Iframe from 'react-iframe'
import { makeStyles, Container, Box, Grid } from '@material-ui/core';
import Suvs from '../images/suvs.webp';

const useStyles = makeStyles((theme) => ({
   boxIframe: {
      overflow: 'hidden !important',
   },
   iframe: {
      width: '1300px',
      // width: '580px',
      // maxWidth: '600px',
      height: '1000px',
      display: 'block',
      position: 'relative',
      border: 'none',
      overflow: 'hidden !important',
      marginTop: '-6.5rem',
      [theme.breakpoints.down('sm')]: {
         width: '350px',
         height: '1370px',
         marginTop: '0',
      }
   },
   boxVeiculos: {
      padding: '3.5rem 1.25rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light, 
      marginTop: '12rem',
      [theme.breakpoints.down('sm')]: {
         marginTop: '0',
         padding: '0 1rem',
         backgroundColor: theme.palette.common.white, 
      }
   },
   suvs: {
      
   },
}));

export default function Calculadora() {
   const classes = useStyles();
   return (
      <Box id='calculadora' mt={{ xs: 12, md: 24 }} mb={{ xs: 4, md: 8 }} mx={{ xs: 0, md: 0 }}>
         <Container>
            <Grid container justifyContent='space-between' spacing={10}>
               <Grid item md={6} className={classes.boxIframe}>
                  <Iframe url="https://new.projetoarea51.com.br/clickmobility/" className={classes.iframe} />
               </Grid>
               <Grid item md={6}>
                  <Box className={classes.boxVeiculos}>
                     <img src={Suvs} className={classes.suvs} width='100%' alt='Click Mobility - SUVs' />
                  </Box>
               </Grid>
            </Grid>
         </Container >
      </Box >
   );
}