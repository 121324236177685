import React from 'react';
import {
   makeStyles, Typography, Grid, Box, Button, Container
} from '@material-ui/core';

// Icons
import { Icon } from '@iconify/react';

// Icons
import Renegade from '../images/renegade-thumb.webp';

// Slide
import Slider from "react-slick";
import '../services/slick.css';
import '../services/slick-theme.css';

const useStyles = makeStyles((theme) => ({
   secondary: {
      color: theme.palette.secondary.main,
   },
   dark: {
      color: theme.palette.common.dark,
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   bold: {
      fontWeight: '600',
   },
   classDescription: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      color: theme.palette.primary.contrastText,
   },
   boxWrapper: {
      // width: '300px',
      // height: '312px',
      [theme.breakpoints.down('sm')]: {
         width: '100vw',
         maxWidth: '340px',
      },
   },
   card: {
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
      color: theme.palette.secondary.main,
      minHeight: '312px',
      boxShadow: 'none',
      padding: '2rem',
      overflow: 'visible',
      margin: '0 16px',
      [theme.breakpoints.down('sm')]: {
         margin: '0 4px',
      },
   },
   dot: {
      fontSize: '1.5rem',
      margin: '0 0.75rem 0 0',
      color: theme.palette.common.light,
   },
   icon: {
      fontSize: '1.5rem',
      margin: '0 0.3rem',
      color: theme.palette.primary.dark,
   },
   iconBg: {
      backgroundColor: theme.palette.primary.light,
      padding: '6px 1px 0px 1px',
      borderRadius: '8px',
      display: 'inline-block',
   },
   listItem: {
      padding: '0',
      marginBottom: '0.75rem',
   },
   boxBg: {
      padding: '2rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
   },
   boxBgSecondary: {
      padding: '2rem',
      borderRadius: '8px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
   },
   boxWhite: {
      padding: '2rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
   },
   buttonOutlined: {
      width: '100%',
      color: theme.palette.primary.dark,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '600',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.dark,
      backgroundColor: 'transparent',
      '&:hover': {
         color: theme.palette.common.white,
         backgroundColor: theme.palette.primary.dark,
      },
      [theme.breakpoints.down('sm')]: {
         padding: '0.65rem 0.5rem !important',
      },
   },
   buttonLabel: {
      textTransform: 'none',
   },
   divSpace: {
      margin: '0 0.5rem',
   },
   boxCar: {
      // width: '100%',
      // height: '440px',
      // borderRadius: '8px',
      minHeight: '322px',
      // backgroundColor: theme.palette.common.light,
   },
   link: {
      color: theme.palette.primary.dark,
      textDecoration: 'none',
   },
   img: {
      margin: '0 auto',
   },
   alert: {
      width: '100%',
      color: theme.palette.common.dark,
      padding: '0.8rem 2.5rem',
      fontSize: '1rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.secondary.light,
      [theme.breakpoints.down('sm')]: {
         padding: '0.65rem 0.5rem !important',
      },
   },
}));

export default function Diferenca() {
   const classes = useStyles();

   // Slide
   var settings = {
      className: 'carrossel',
      infinite: false,
      speed: 1000,
      fade: false,
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 5,
      swipeToSlide: true,
      arrows: true,
      dots: false,
      responsive: [
         {
            breakpoint: 1441,
            settings: {
               slidesToShow: 4,
            }
         },
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 2,
            }
         },
         {
            breakpoint: 720,
            settings: {
               slidesToShow: 2,
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
            }
         }
      ],
      afterChange: function (index) {
         console.log(
            `Slider Changed to: ${index + 1}`
         );
      }
   };

   return (

      <Box mt={{ xs: 16, md: 28 }} mb={{ xs: 16, md: 28 }} mx={{ xs: 4, md: 0 }} pb={{ xs: 12, md: 0 }} md={10}>
         {/* <Container> */}

         {/* <Box> */}
         <Grid container justifyContent='space-evenly' alignItems='flex-start'>

            {/* Lado esquerdo */}
            {/* <Grid container md='3'>
               <Box pl={{ xs: 0, md: 24}} pr={{ xs: 0, md: 0}}>
                  <Grid item md='8'>
                     <Box mb={4} className={classes.iconBg}>
                        <Icon icon="ph:navigation-arrow" className={classes.icon} />
                     </Box>
                     <Box mb={8}>
                        <Typography component='h2' variant='h2' className={classes.dark}>
                           Conheça nossa frota e faz o click.
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md='7'>
                     <Box mb={8}>
                        <Typography component='p' variant='body1' className={classes.classDescription}>
                           Veja todos os carros que estão disponíveis para você.
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 20, md: 0 }}>
                        <Button color='secondary' variant='outlined' href='https://assinatura.meuclickmobility.com.br'
                           classes={{
                              root: classes.buttonOutlined,
                              label: classes.buttonLabel,
                           }}>Ver todos os carros
                        </Button>
                     </Box>
                  </Grid>
               </Box>
            </Grid> */}
            <Grid item md={12}>
               <Box mt={8} mb={14}>
                  <Grid container justifyContent='center' alignItems='flex-start'>
                     {/* <Grid item>
                        <Box className={classes.iconBg}>
                           <Icon icon="ph:navigation-arrow" className={classes.icon} />
                        </Box>
                     </Grid> */}
                     <Grid item>
                        <Typography component='h2' variant='h2' className={classes.dark}>
                           Conheça nossa frota
                        </Typography>
                     </Grid>
                  </Grid>
               </Box>
            </Grid>

            {/* <Box mt={{ xs: 24, md: 0 }}> */}
            <Grid container md='12' justifyContent='center' alignContent='center' alignItems='center' spacing={4}>
               <Slider {...settings}>

                  {/* Kicks */}
                  <Box item className={classes.boxCar}>
                     <Box textAlign='center' py={8}>
                        <Box mb={8} mt={1}>
                           <img src='https://assinatura.meuclickmobility.com.br/wp-content/uploads/2022/12/74819-Imagem-ve-culo-PNG-_-539x343-1-400x255.png'
                              className={classes.img} width='auto' height='133px' alt='Click Mobility - Kicks Advance CVT' />
                        </Box>
                        <Box mb={4}>
                           <Typography component='h5' variant='h5'>Kicks</Typography>
                           {/* <Typography component='p' variant='body1' className={classes.contrastText}>Modelo SUV</Typography> */}
                           <a href='https://assinatura.meuclickmobility.com.br/?s=Novo+Kicks&post_type=product&dgwt_wcas=1' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>

                  {/* Cronos */}
                  <Box item className={classes.boxCar}>
                     <Box textAlign='center' py={8}>
                        <Box mb={8}>
                           <img src='https://assinatura.meuclickmobility.com.br/wp-content/uploads/2022/12/foto730_32013-400x260.png' className={classes.img} width='auto' height='133px' alt='Click Mobility - Fiat Cronos' />
                        </Box>
                        <Box mb={4}>
                           <Typography component='h5' variant='h5'>Cronos</Typography>
                           {/* <Typography component='p' variant='body1' className={classes.contrastText}>Modelo Sedan</Typography> */}
                           <a href='https://assinatura.meuclickmobility.com.br/carro/cronos-1-0-2023/' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>

                  {/* Pulse Drive 1.3 */}
                  <Box item className={classes.boxCar}>
                     <Box textAlign='center' py={8}>
                        <Box mb={8}>
                           <img
                              src='https://assinatura.meuclickmobility.com.br/wp-content/uploads/2023/02/foto730_31812-400x260.png'
                              className={classes.img} width='auto' height='133px' alt='Click Mobility - Pulse Drive 1.3' />
                        </Box>
                        <Box mb={4}>
                           <Typography component='h5' variant='h5'>Pulse</Typography>
                           {/* <Typography component='p' variant='body1' className={classes.contrastText}>Modelo SUV</Typography> */}
                           <a href='https://assinatura.meuclickmobility.com.br/?s=Pulse&post_type=product&dgwt_wcas=1' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>

                  {/* Renegade */}
                  <Box item className={classes.boxCar}>
                     <Box textAlign='center' py={8}>
                        <Box mb={8} mt={1}>
                           <img src={Renegade}
                              className={classes.img} width='auto' height='130px' alt='Click Mobility - Jeep Renegade' />
                        </Box>
                        <Box mb={4}>
                           <Typography component='h5' variant='h5'>Renegade</Typography>
                           {/* <Typography component='p' variant='body1' className={classes.contrastText}>Modelo SUV</Typography> */}
                           <a href='https://assinatura.meuclickmobility.com.br/?s=Renegade&post_type=product&dgwt_wcas=1' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>

                  {/* Creta */}
                  <Box item className={classes.boxCar}>
                     <Box textAlign='center' py={8}>
                        <Box mb={8}>
                           <img src='https://assinatura.meuclickmobility.com.br/wp-content/uploads/2023/02/03350-CRETA-Branco-573x320--400x223.png' className={classes.img} width='auto' height='133px' alt='Click Mobility - Creta' />
                        </Box>
                        <Box mb={4}>
                           <Typography component='h5' variant='h5'>Creta</Typography>
                           {/* <Typography component='p' variant='body1' className={classes.contrastText}>Modelo SUV</Typography> */}
                           <a href='https://assinatura.meuclickmobility.com.br/?s=New+Creta&post_type=product&dgwt_wcas=1' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>

                  {/* Fastback */}
                  <Box item className={classes.boxCar}>
                     <Box textAlign='center' py={8}>
                        <Box mb={8} mt={3}>
                           <img
                              src='https://assinatura.meuclickmobility.com.br/wp-content/uploads/2023/02/foto730_32903-400x260.png'
                              className={classes.img} width='auto' height='120px' alt='Click Mobility - Fastback' />
                        </Box>
                        <Box mb={4}>
                           <Typography component='h5' variant='h5'>Fastback</Typography>
                           {/* <Typography component='p' variant='body1' className={classes.contrastText}>Modelo Hatch</Typography> */}
                           <a href='https://assinatura.meuclickmobility.com.br/?s=Fastback&post_type=product&dgwt_wcas=1' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>

                  {/* Oroch */}
                  <Box item className={classes.boxCar}>
                     <Box textAlign='center' py={8}>
                        <Box mb={6} mt={-1}>
                           <img
                              src='https://assinatura.meuclickmobility.com.br/wp-content/uploads/2023/02/58716-Imagem-Ve-culo-400x255.png'
                              className={classes.img} width='auto' height='145px' alt='Click Mobility - Oroch Intense 1.6' />
                        </Box>
                        <Box mb={4}>
                           <Typography component='h5' variant='h5'>Oroch Intense 1.6</Typography>
                           {/* <Typography component='p' variant='body1' className={classes.contrastText}>Modelo Picape</Typography> */}
                           <a href='https://assinatura.meuclickmobility.com.br/carro/oroch-intense-1-6/' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>

                  {/* Outros */}
                  <Box item className={classes.boxCar}>
                     <Box textAlign='center' px={8} py={8}>
                        <Box mt={8} mb={4} className={classes.iconBg}>
                           <Icon icon="fluent:vehicle-car-profile-ltr-20-regular" className={classes.icon} />
                        </Box>
                        <Box mb={12}>
                           <Typography component='p' variant='h5' className={classes.contrastText}>
                              Veja toda nossa frota disponível para você.
                           </Typography>
                           {/* <Typography component='p' variant='body1'>
                              Temos diversos veículos e modelos para você escolher. Clique no botão abaixo e saiba mais.
                           </Typography> */}
                        </Box>
                        <Box px={8} mb={{ xs: 20, md: 0 }}>
                           <Button color='secondary' variant='outlined' href='https://assinatura.meuclickmobility.com.br'
                              classes={{
                                 root: classes.buttonOutlined,
                                 label: classes.buttonLabel,
                              }}>Ver carros
                           </Button>
                        </Box>
                     </Box>
                  </Box>

               </Slider>

               <Container>
                  <Grid container justifyContent='flex-start'>
                     <Grid item xs={12} md={4}>
                        <Box mt={{ xs: 12, md: 5 }} px={{ xs: 4, md: 0 }} textAlign='center'>
                           <Typography component='p' variant='body2' className={classes.alert}>*Nossos planos não atendem motoristas de aplicativo 😊</Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Container>

            </Grid>
            {/* </Box> */}

         </Grid>
         {/* </Box> */}

         {/* </Container> */}
      </Box>
   );
}