import React from 'react';
import { Button, makeStyles, Typography, Container, Grid, Box } from '@material-ui/core';

import Bg from '../images/bg.webp';

const useStyles = makeStyles((theme) => ({
   section: {
      width: '100%',
      height: '100vh',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left top',
      backgroundImage: `url(${Bg})`,
      [theme.breakpoints.down('sm')]: {
         height: '100%',
      },
   },
   container: {
      paddingTop: '96px',
      position: 'relative',
      zIndex: '1',
   },
   buttonLabel: {
      textTransform: 'none',
   },
   button: {
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '800',
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
         backgroundColor: theme.palette.secondary.main,
      },
   },
   icon: {
      float: 'left',
      fontSize: '2rem',
      margin: '-7px 5px 0 0',
      color: theme.palette.secondary.main,
   }
}));

function Header(props) {
   const classes = useStyles();
   return (
      <Grid className={classes.section}>
         <Container className={classes.container}>
            <Box py={{ xs: 4, md: 4 }}>
               <Grid container direction='row' justifyContent={{ xs: 'center', md: 'space-between' }} alignItems='center' alignContent='center'>
                  <Grid item md='4' className={props.classBoxText}>
                     <Box mt={{ xs: 6, md: -28 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 0 }}>
                        <Typography variant='h1' component='h2'>
                           <Box mb={2} className={props.classSubTitle}>
                              {props.subtitle}
                           </Box>
                        </Typography>
                        <Typography variant='h1'>
                           <Box mb={6} className={props.classTitle}>{props.title}</Box>
                        </Typography>
                        <Typography variant='h5'>
                           <Box mb={{ xs: 12 }} className={props.classDescription}>{props.description}</Box>
                        </Typography>
                        <Button color='secondary' variant='contained' href={props.linkButton} 
                           classes={{
                              root: props.classButton,
                              label: classes.buttonLabel,
                           }}>{props.buttonLabel}
                        </Button>
                     </Box>
                  </Grid>
                  <Grid item md='8' className={props.classBoxImage}>
                     <Box mt={{ xs: 4, md: 0 }} mb={{ xs: 0, md: 0 }} pr={{ xs: 0, md: 0 }}>
                        <img src={props.image} alt={props.imageAlt} className={props.classImage} width='100%' />
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Container>
      </Grid>
   );
}

export default Header;