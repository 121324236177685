import React from 'react';
import { makeStyles, Container, Typography, Grid, Box } from '@material-ui/core';

import StarIcon from '@mui/icons-material/Star';

// Images
// import Roster1 from '../images/roster-1.webp';
// import Roster2 from '../images/roster-2.webp';
// import Roster3 from '../images/roster-3.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#2D2D2D',
   },
   bg: {
      zIndex: '1',
      position: 'relative',
      backgroundColor: theme.palette.common.light,
   },
   bgGrey: {
      backgroundColor: '#F2F2F2 !important',
   },
   box: {
      minHeight: '130px',
      overflow: 'hidden',
      borderRadius: '8px',
      padding: '2rem',
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
         padding: '2rem',
      }
   },
   icon: {
      width: '1rem !important',
      height: '1rem !important',
      color: '#FFB800',
   },
   iconBg: {
      width: '2.5rem !important',
      height: '2.5rem !important',
      borderRadius: '25px',
      padding: '0.5rem',
      backgroundColor: theme.palette.primary.light,
   },
   bold: {
      fontWeight: '800',
   },
   dark: {
      color: theme.palette.common.dark,
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   iconSmall: {
      width: '1.15rem !important',
      height: '1.15rem !important',
      marginRight: '1.5rem',
      color: theme.palette.secondary.dark,
   },
   tag: {
      backgroundColor: theme.palette.common.white,
      borderRadius: '7px',
      padding: '0.35rem 0.75rem',

   },
   buttonOutlined: {
      width: '100%',
      color: theme.palette.primary.main,
      boxShadow: 'none',
      borderRadius: '25px',
      textTransform: 'none',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'transparent',
      '&:hover': {
         color: theme.palette.common.black,
         backgroundColor: theme.palette.primary.main,
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
      },
   },
   button: {
      width: '100%',
      color: theme.palette.common.black,
      boxShadow: 'none',
      borderRadius: '25px',
      textTransform: 'none',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         backgroundColor: theme.palette.primary.main,
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
      },
   },
   link: {
      textDecoration: 'none !important',
   },
}));

export default function Home() {
   const classes = useStyles();

   return (

      <Box id='depoimentos' pt={20} pb={40} px={{ xs: 4, md: 0 }} className={classes.bg}>
         <Container>

            <Grid container justifyContent='space-between' alignItems='center'>
               <Grid item md={5}>
                  <Box mb={3}>
                     <Typography variant='h2' component='h2' className={classes.dark}>
                        Confira o que os clientes têm a dizer sobre nossos serviços.
                     </Typography>
                  </Box>
               </Grid>
            </Grid>

            <Box mt={12} mb={8}>
               <Grid container justifyContent='space-between' spacing={8}>

                  {/* Depoimento 1 */}
                  <Grid item md={4}>
                     <a
                        href='https://goo.gl/maps/JT68oZP32uSRbD9y5'
                        target='_blank'
                        rel="noreferrer"
                        className={classes.link}
                     >
                        <Box className={`${classes.box}`}>
                           <Grid container justifyContent='space-between'>
                              <Grid item xs={2}>
                                 <img
                                    src='https://lh3.googleusercontent.com/a-/ACNPEu9p_xt7BRuIhamoEr1eqyOZcnHc7SKm0B4pdkP9Og=w36-h36-p-rp-mo-ba4-br100'
                                    alt='Click Mobility'
                                    width='40px'
                                 />
                              </Grid>
                              <Grid item xs={10}>
                                 <Box pl={{ xs: 2, md: 0 }}>
                                    <Typography variant='body1' component='h5' className={classes.dark}>
                                       Carlos Eduardo
                                    </Typography>
                                    <Typography variant='body1' component='p' className={classes.contrastText}>
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                    </Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={12}>
                                 <Box mt={3}>
                                    <Typography variant='body1' component='p' className={classes.contrastText}>
                                       "Concessionária Nota 10, Equipe Bem Atualizada, Informações Super Detalhadas, muito obrigado, continuem assim. 
                                       Excelente, 5 estrelas, Recomendo. Obrigado Silvano, pelo Atendimento 5 estrelas que foi conduzido com excelência, 
                                       até o dia da entrega do veiculo."
                                    </Typography>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Box>
                     </a>
                  </Grid>

                  {/* Depoimento 2 */}
                  <Grid item md={4}>
                     <a
                        href='https://goo.gl/maps/DsBm4bL4gQv9YV5p7'
                        target='_blank'
                        rel="noreferrer"
                        className={classes.link}
                     >
                        <Box className={`${classes.box}`}>
                           <Grid container justifyContent='space-between' spacing={2}>
                              <Grid item xs={2}>
                                 <img
                                    src='https://lh3.googleusercontent.com/a-/ACNPEu-ktsF_9MahvwYuqKspNa-GG5II8aQT1T6G0g4bRnk=w36-h36-p-c0x00000000-rp-mo-br100'
                                    alt='Click Mobility'
                                    width='40px'
                                 />
                              </Grid>
                              <Grid item xs={10}>
                                 <Box pl={{ xs: 2, md: 0 }}>
                                    <Typography variant='body1' component='h5' className={classes.dark}>
                                       Cleber de Jesus
                                    </Typography>
                                    <Typography variant='body1' component='p' className={classes.contrastText}>
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                    </Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={12}>
                                 <Box mt={3}>
                                    <Typography variant='body1' component='p' className={classes.contrastText}>
                                       “Fui olhar um carro que sempre achei bonito, na entrada o vendedor Silvan nos 
                                       abordou e mostrou todos os veículos, nos deixando a vontade, explicando, fez o possível 
                                       para nossa proposta ser aceita. Hoje saio da loja com meu carro zero, feliz pelo 
                                       acolhimento do vendedor Silvan e da loja toda. Funcionários muito simpáticos.  
                                       Indico com certeza.”
                                    </Typography>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Box>
                     </a>
                  </Grid>

                  {/* Depoimento 3 */}
                  <Grid item md={4}>
                     <a
                        href='https://goo.gl/maps/qqEiJtigs583TQ6aA'
                        target='_blank'
                        rel="noreferrer"
                        className={classes.link}
                     >
                        <Box className={`${classes.box}`}>
                           <Grid container justifyContent='space-between' spacing={2}>
                              <Grid item xs={2}>
                                 <img
                                    src='https://lh3.googleusercontent.com/a-/ACNPEu88mZl4a6ZKfzAN9XfayvMcIKYC-uAMOI_dJCQyzJU=w60-h60-p-rp-mo-ba3-br100'
                                    alt='Click Mobility'
                                    width='40px'
                                 />
                              </Grid>
                              <Grid item xs={10}>
                                 <Box pl={{ xs: 2, md: 0 }}>
                                    <Typography variant='body1' component='h5' className={classes.dark}>
                                       Wallace Pereira
                                    </Typography>
                                    <Typography variant='body1' component='p' className={classes.contrastText}>
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                       <StarIcon className={classes.icon} />
                                    </Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={12}>
                                 <Box mt={3}>
                                    <Typography variant='body1' component='p' className={classes.contrastText}>
                                       “Tive uma experiência de atendimento exemplar, foram realizadas as explicações 
                                       sobre os serviços realizados em meu veículo, tudo perfeito! Os profissionais são 
                                       bem treinados e capacitados para prestar um atendimento com excelência. 
                                       Parabéns Grupo Leauto pela estrutura e excelentes profissionais contratados!!👏👏👏”
                                    </Typography>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Box>
                     </a>
                  </Grid>

               </Grid>
            </Box>

         </Container>
      </Box>

   );
}