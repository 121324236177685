import React from 'react';
import {
   makeStyles, Typography, Container, Grid, Box, Button
} from '@material-ui/core';

// Icons
import { Icon } from '@iconify/react';

const useStyles = makeStyles((theme) => ({
   bg: {
      backgroundColor: theme.palette.common.light,
   },
   secondary: {
      color: theme.palette.secondary.main,
   },
   dark: {
      color: theme.palette.common.dark,
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   bold: {
      fontWeight: '600',
   },
   icon: {
      fontSize: '1.5rem',
      margin: '0 0.3rem',
      color: theme.palette.primary.dark,
   },
   iconBig: {
      fontSize: '1.75rem',
      margin: '0 0.3rem',
      color: theme.palette.primary.dark,
   },
   iconCheck: {
      fontSize: '3rem',
      margin: '0 0.3rem',
      color: theme.palette.primary.dark,
   },
   iconBg: {
      backgroundColor: theme.palette.common.white,
      padding: '6px 1px 0px 1px',
      borderRadius: '8px',
      display: 'inline-block',
   },
   iconBgCircle: {
      backgroundColor: theme.palette.common.light,
      padding: '12px 8px 8px 8px',
      borderRadius: '25px',
      display: 'inline-block',
   },
   boxBg: {
      padding: '2rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
   },
   boxBgSecondary: {
      padding: '2rem',
      borderRadius: '8px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
   },
   buttonLabel: {
      textTransform: 'none',
   },
   button: {
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 1.65rem',
      fontSize: '1rem',
      fontWeight: '600',
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
         backgroundColor: theme.palette.secondary.main,
      },
   },
   hr: {
      height: '0',
      border: '1px solid rgba(111, 111, 120, 0.1)',
      margin: '6rem 0 1rem 0',
      width: '100%',
   },
   marginCenter: {
      margin: 'auto',
   },
   display: {
      display: 'block',
      [theme.breakpoints.down('sm')]: {
         display: 'none',
      },
   }
}));

export default function Diferenca() {
   const classes = useStyles();

   return (

      <Box mt={{ xs: 0, md: 0 }} mb={{ xs: 0, md: 0 }} px={{ xs: 4, md: 0 }} py={{ xs: 12, md: 30 }}>
         <Container>
            <Grid container justifyContent='space-between' alignItems='center'>

               <Grid item md={12}>
                  <Box mb={20}>
                     <Grid container justifyContent='center' spacing={8} alignItems='flex-start'>
                        <Grid item>
                           <Box className={classes.iconBg}>
                              <Icon icon="ph:navigation-arrow" className={classes.icon} />
                           </Box>
                        </Grid>
                        <Grid item>
                           <Typography component='h2' variant='h2' className={classes.dark}>
                              Seu carro zero na<br /> garagem em 5 clicks
                           </Typography>
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>

               {/* <Box px={{ xs: 4, md: 0 }} textAlign={{ xs: 'center', md: 'left' }}> */}
                  <Grid container justifyContent='space-between' spacing={8}>

                     {/* Click 1 */}
                     <Grid item xs={12} md={2}>
                        <Grid container justifyContent='space-between' alignItems='center' spacing={8}>
                           <Grid item xs={12} md={10} className={classes.marginCenter}>
                              <Box textAlign='center'>
                                 <Box className={classes.iconBgCircle}>
                                    <Icon icon="fluent:vehicle-car-profile-ltr-20-regular" className={classes.iconBig} />
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item md={2} className={classes.display}>
                              <Icon icon="bi:arrow-right-short" className={classes.icon} />
                           </Grid>
                        </Grid>
                        <Grid item md={10}>
                           <Box mt={6} mb={{ xs: 4, md: 0 }} textAlign='center'>
                              <Typography component='div' variant='body1' className={classes.contrastText}>
                                 Escolha seu carro
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>

                     {/* Click 2 */}
                     <Grid item xs={12} md={2}>
                        <Grid container justifyContent='space-between' alignItems='center' spacing={8}>
                           <Grid item xs={12} md={10} className={classes.marginCenter}>
                              <Box textAlign='center'>
                                 <Box className={classes.iconBgCircle}>
                                    <Icon icon="octicon:hourglass-24" className={classes.iconBig} />
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item md={2} className={classes.display}>
                              <Icon icon="bi:arrow-right-short" className={classes.icon} />
                           </Grid>
                        </Grid>
                        <Grid item md={10}>
                           <Box mt={6} mb={{ xs: 4, md: 0 }} textAlign='center'>
                              <Typography component='div' variant='body1' className={classes.contrastText}>
                                 Defina seu tempo de assinatura e franquia
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>

                     {/* Click 3 */}
                     <Grid item xs={12} md={2}>
                        <Grid container justifyContent='space-between' alignItems='center' spacing={8}>
                           <Grid item xs={12} md={10} className={classes.marginCenter}>
                              <Box textAlign='center'>
                                 <Box className={classes.iconBgCircle}>
                                    <Icon icon="clarity:pencil-line" className={classes.iconBig} />
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item md={2} className={classes.display}>
                              <Icon icon="bi:arrow-right-short" className={classes.icon} />
                           </Grid>
                        </Grid>
                        <Grid item md={10}>
                           <Box mt={6} mb={{ xs: 4, md: 0 }} textAlign='center'>
                              <Typography component='div' variant='body1' className={classes.contrastText}>
                                 Preencha os dados
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>

                     {/* Click 4 */}
                     <Grid item xs={12} md={2}>
                        <Grid container justifyContent='space-between' alignItems='center' spacing={8}>
                           <Grid item xs={12} md={10} className={classes.marginCenter}>
                              <Box textAlign='center'>
                                 <Box className={classes.iconBgCircle}>
                                    <Icon icon="clarity:contract-line" className={classes.iconBig} />
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item md={2} className={classes.display}>
                              <Icon icon="bi:arrow-right-short" className={classes.icon} />
                           </Grid>
                        </Grid>
                        <Grid item md={10}>
                           <Box mt={6} mb={{ xs: 4, md: 0 }} textAlign='center'>
                              <Typography component='div' variant='body1' className={classes.contrastText}>
                                 Assine o contrato
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>

                     {/* Click 5 */}
                     <Grid item xs={12} md={2}>
                        <Grid container justifyContent='space-between' alignItems='center' spacing={8}>
                           <Grid item xs={12} md={12} className={classes.marginCenter}>
                              <Box textAlign='center'>
                                 <Box className={classes.iconBgCircle}>
                                    <Icon icon="ep:location" className={classes.iconBig} />
                                 </Box>
                              </Box>
                           </Grid>
                        </Grid>
                        <Grid item md={12}>
                           <Box mt={6} mb={{ xs: 4, md: 0 }} textAlign='center'>
                              <Typography component='div' variant='body1' className={classes.contrastText}>
                                 E pé na estrada
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>

                     {/* Botão */}
                     <Grid container justifyContent='center'>
                        <Grid item>
                           <Box mt={4} textAlign='center'>
                              {/* <Icon icon="akar-icons:circle-check" className={classes.iconCheck} /> */}
                              <Box mt={16}>
                                 <Button color='secondary' variant='contained' href='https://assinatura.meuclickmobility.com.br'
                                    classes={{
                                       root: classes.button,
                                       label: classes.buttonLabel,
                                    }}>Pensou em carro zero? Click!
                                 </Button>
                              </Box>
                           </Box>
                        </Grid>
                     </Grid>

                  </Grid>
               {/* </Box> */}

               {/* <hr className={classes.hr} /> */}

            </Grid>
         </Container>
      </Box>
   );
}