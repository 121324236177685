import React from 'react';
import {
   makeStyles, Typography, Container, Grid, Box, Button,
   List, ListItem, ListItemIcon,
} from '@material-ui/core';

// Icons
import { Icon } from '@iconify/react';

// Images
import Avatares from '../images/avatares.webp';

const useStyles = makeStyles((theme) => ({
   bg: {
      backgroundColor: theme.palette.common.light,
   },
   secondary: {
      color: theme.palette.secondary.main,
   },
   dark: {
      color: theme.palette.common.dark,
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   bold: {
      fontWeight: '600',
   },
   classDescription: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      color: theme.palette.primary.contrastText,
   },
   boxWrapper: {
      // width: '300px',
      // height: '312px',
      [theme.breakpoints.down('sm')]: {
         width: '100vw',
         maxWidth: '340px',
      },
   },
   card: {
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
      color: theme.palette.secondary.main,
      minHeight: '312px',
      boxShadow: 'none',
      padding: '2rem',
      overflow: 'visible',
      margin: '0 16px',
      [theme.breakpoints.down('sm')]: {
         margin: '0 4px',
      },
   },
   dot: {
      fontSize: '1.5rem',
      margin: '0 0.75rem 0 0',
      color: theme.palette.common.light,
   },
   icon: {
      fontSize: '1.5rem',
      margin: '0 0.3rem',
      color: theme.palette.primary.dark,
   },
   iconBg: {
      backgroundColor: theme.palette.primary.light,
      padding: '6px 1px 0px 1px',
      borderRadius: '8px',
      display: 'inline-block',
   },
   iconBgWhite: {
      backgroundColor: theme.palette.common.white,
      padding: '6px 1px 0px 1px',
      borderRadius: '8px',
      display: 'inline-block',
   },
   listItem: {
      padding: '0',
      marginBottom: '0.75rem',
   },
   boxBg: {
      padding: '2rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
   },
   boxBgSecondary: {
      padding: '2rem',
      borderRadius: '8px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
   },
   boxWhite: {
      padding: '2rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
   },
   buttonLabel: {
      textTransform: 'none',
   },
   button: {
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 1.65rem',
      fontSize: '1rem',
      fontWeight: '600',
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
         backgroundColor: theme.palette.secondary.main,
      },
   },
   divSpace: {
      margin: '0 0.5rem',
   },
   boxCar: {
      width: '100%',
      height: '440px',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
   },
}));

export default function Diferenca() {
   const classes = useStyles();

   return (

      <Box className={classes.bg} mt={{ xs: 0, md: 0 }} mb={{ xs: 4, md: 0 }} px={{ xs: 4, md: 0 }} pt={{ xs: 12, md: 20 }} pb={{ xs: 12, md: 0 }}>
         <Container>


            <Box>
               <Grid container justifyContent='space-between' alignItems='flex-start'>

                  {/* Lado esquerdo */}
                  <Grid container md='4'>
                     <Grid item md='10'>
                        <Box mb={4} className={classes.iconBgWhite}>
                           <Icon icon="ph:navigation-arrow" className={classes.icon} />
                        </Box>
                        <Box mb={8}>
                           <Typography component='h2' variant='h2' className={classes.dark}>
                              Bem mais que um carro, conforto e segurança ao seu dispor.
                           </Typography>
                        </Box>
                     </Grid>
                     <Grid item md='8'>
                        <Box mb={8}>
                           <Typography component='p' variant='body1' className={classes.classDescription}>
                              Revolucione sua relação com um carro e preocupe-se apenas com o que realmente importa - você.
                           </Typography>
                        </Box>
                        <Button color='secondary' variant='contained' href='https://assinatura.meuclickmobility.com.br'
                           classes={{
                              root: classes.button,
                              label: classes.buttonLabel,
                           }}>Assine agora mesmo
                        </Button>
                     </Grid>
                     <Grid item md={12}>
                        <Box mt={12} mb={8}>
                           <img src={Avatares} width='100%' alt='Click Mobility' />
                        </Box>
                     </Grid>
                  </Grid>

                  <Grid container md='8' justifyContent='space-between' alignItems='flex-start' spacing={8}>

                     <Grid item md={6}>
                        {/* Bloco 1 */}
                        <Box className={classes.boxWhite} mb={8}>
                           <Grid container justifyContent='flex-start' spacing={8} alignItems='flex-start'>
                              <Grid item>
                                 <Box className={classes.iconBg}>
                                    <Icon icon="ph:currency-circle-dollar-bold" className={classes.icon} />
                                 </Box>
                              </Grid>
                              <Grid item>
                                 <Typography component='h5' variant='h4' className={classes.dark}>
                                    O melhor custo <br />benefício
                                 </Typography>
                              </Grid>
                              <Grid item md={11}>
                                 <List>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>Custo de manutenção super reduzido;</Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>Zero prejuízo com depreciação;</Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>
                                                Seu capital livre para investir no que quiser;
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                 </List>
                              </Grid>
                           </Grid>
                        </Box>
                        {/* Bloco 2 */}
                        <Box className={classes.boxWhite}>
                           <Grid container justifyContent='flex-start' spacing={8} alignItems='flex-start'>
                              <Grid item>
                                 <Box className={classes.iconBg}>
                                    <Icon icon="uil:shield-check" className={classes.icon} />
                                 </Box>
                              </Grid>
                              <Grid item>
                                 <Typography component='h5' variant='h4' className={classes.dark}>
                                    Segurança e <br />liberdade
                                 </Typography>
                              </Grid>
                              <Grid item md={11}>
                                 <List>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>
                                                Agendamos revisões preventivas no seu carro de acordo com os seus
                                                dados de uso obtidos pela nossa telemetria inteligente sem você sair de casa.
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>
                                                Assistência e atendimento 24h por dia para ajudar em qualquer imprevisto.
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>
                                                Seu carro é blindado com as empresas mais renomadas do mercado.
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                 </List>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>

                     <Grid item md={6}>
                        {/* Bloco 3 */}
                        <Box className={classes.boxWhite} mb={8}>
                           <Grid container justifyContent='flex-start' spacing={8} alignItems='flex-start'>
                              <Grid item>
                                 <Box className={classes.iconBg}>
                                    <Icon icon="carbon:flash" className={classes.icon} />
                                 </Box>
                              </Grid>
                              <Grid item>
                                 <Typography component='h5' variant='h4' className={classes.dark}>
                                    Agilidade e praticidade <br />na contratação
                                 </Typography>
                              </Grid>
                              <Grid item md={11}>
                                 <List>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>
                                                Aprovação de crédito baseada no valor da locação, não do carro.
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>
                                                Taxas, IPVA, DPVAT, documentação, seguro e burocracias por nossa conta.
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>
                                                Você já sai com o carro do seu jeito e sem precisar se preocupar com mais nada.
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                 </List>
                              </Grid>
                           </Grid>
                        </Box>
                        {/* Bloco 4 */}
                        <Box className={classes.boxWhite}>
                           <Grid container justifyContent='flex-start' spacing={8} alignItems='flex-start'>
                              <Grid item>
                                 <Box className={classes.iconBg}>
                                    <Icon icon="bx:like" className={classes.icon} />
                                 </Box>
                              </Grid>
                              <Grid item>
                                 <Box mt={2}>
                                    <Typography component='h5' variant='h4' className={classes.dark}>
                                       Conforto
                                    </Typography>
                                 </Box>
                              </Grid>
                              <Grid item md={11}>
                                 <List>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>
                                                Na hora da entrega você fica sabendo de cada detalhe do seu carro zero.
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>
                                                Aqui você pode customizar o carro do jeito que você quiser.
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                       <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                          <Grid item xs={2} md={1}>
                                             <ListItemIcon>
                                                <Icon icon="ci:dot-03-m" className={classes.dot} />
                                             </ListItemIcon>
                                          </Grid>
                                          <Grid item xs={10} md={11}>
                                             <Typography component='p' variant='body1' className={classes.contrastText}>
                                                Ao fim do contrato você poderá renovar recebendo outro carro zero do jeitinho que você quiser.
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </ListItem>
                                 </List>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>

                  </Grid>

               </Grid>
            </Box>

         </Container>
      </Box>
   );
}