import React, { useState } from 'react';
import { makeStyles, AppBar, Toolbar, Container, Grid, Box, Button, Typography } from '@material-ui/core';
import TopBarMobile from './TopBarMobile';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

// Icons
// import { PersonOutline } from '@material-ui/icons';

//IMAGES
import Logo from '../images/logo.webp';

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.common.light,
    padding: '1rem 0',
  },
  appBarColor: {
    backgroundColor: theme.palette.common.light,
    boxShadow: '0px -2px 13px #CCC',
  },
  grow: {
    flexGrow: 1,
  },
  slide: {
    backgroundColor: '#000',
  },
  menuItem: {
    cursor: 'pointer',
    fontSize: '1rem',
    lineHeight: '2rem',
    color: theme.palette.common.main,
    transitionDuration: '400ms',
    textDecoration: 'none',
    fontFamily: 'Lato, sans-serif',
    '&:focus, &:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'rgba(0,0,0,0.7)',
      '&:focus, &:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  },
  icons: {
    color: 'white',
    fontSize: '1rem',
    paddingLeft: '.6rem',
    paddingRight: '.6rem',
    [theme.breakpoints.down('sm')]: {
      color: 'rgba(0,0,0,0.6)',
    },
  },
  button: {
    color: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: '8px',
    textTransform: 'none',
    padding: '0.65rem 2.5rem',
    fontSize: '1rem',
    fontWeight: '800',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonOutlined: {
    width: '100%',
    color: theme.palette.common.dark,
    boxShadow: 'none',
    borderRadius: '8px',
    textTransform: 'none',
    padding: '0.65rem 2.5rem',
    fontSize: '1rem',
    fontWeight: '600',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.dark,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonLabel: {
    textTransform: 'none',
  },
  icon: {
    fontSize: '1.25rem',
    margin: '0 0.5rem 0 0',
    paddingTop: '0.1rem',
    color: theme.palette.common.dark,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  leauto: {
    color: theme.palette.primary.dark,
    fontWeight: '700',
    textDecoration: 'none',
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Header(props) {

  const classes = useStyles();
  const [appbar, setAppbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 175) {
      setAppbar(true);
    } else {
      setAppbar(false);
    }
  };
  window.addEventListener('scroll', changeBackground);

  return (

    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar color='inherit' className={`${classes.appBar} ${appbar ? classes.appBarColor : ''}`}>
          <Toolbar>
            <Container>
              <Grid container justifyContent='space-between' alignItems='center' >

                {/* Menu de navegação esquerda */}
                {/* <Grid item xs={0} md={3} className={classes.menuItem}>
                  <Box display={{ xs: 'none', sm: 'none', md: 'flex' }} justifyContent='center' flexWrap='nowrap'>
                    <Grid container justifyContent='space-between'>
                      <Grid item><a className={classes.menuItem} href='/'>Home</a></Grid>
                      <Grid item><a className={classes.menuItem} href='/veiculos'>Seminovos</a></Grid>
                      <Grid item><a className={classes.menuItem} href='https://promo.brasilautomoveisrj.com.br/cacadores-venda/'>Quero vender</a></Grid>
                    </Grid>
                  </Box>
                </Grid> */}

                {/* Logo */}
                <Grid item xs={10} md={2}>
                  <Box textAlign={{ xs: 'left', md: 'left' }}>
                    <a href='/'><img src={Logo} alt='Logo' /></a>
                    <Box mt={2}>
                      <Typography component='p' variant='body2'>
                        Uma empresa do <a className={classes.leauto} href='https://grupoleauto.com.br/' target='_blank' rel="noreferrer">Grupo Leauto</a>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* Menu de navegação direita */}
                <Grid item xs={0} md={5}>
                  <Box display={{ xs: 'none', sm: 'none', md: 'flex' }} justifyContent='flex-end' flexWrap='nowrap'>
                    <Grid container justifyContent='space-between'>
                      <Grid item><a className={classes.menuItem} href='/'>Página Inicial</a></Grid>
                      <Grid item><a className={classes.menuItem} href='/quem-somos'>Quem Somos</a></Grid>
                      <Grid item><a className={classes.menuItem} href='https://assinatura.meuclickmobility.com.br'>Nossa Frota</a></Grid>
                      <Grid item><a className={classes.menuItem} href='/duvidas'>Tire suas dúvidas</a></Grid>
                      {/* <Grid item><a className={classes.menuItem} href='/politica-de-privacidade'>Política de Privacidade</a></Grid> */}
                    </Grid>
                  </Box>
                </Grid>

                {/* Botão Minha conta */}
                <Grid item xs={0} md={2}>
                  <Box display={{ xs: 'none', sm: 'none', md: 'flex' }} justifyContent='flex-end' flexWrap='nowrap'>
                    <Grid container justifyContent='flex-end'>
                      <Button className={classes.button} variant='contained' href='https://assinatura.meuclickmobility.com.br'>
                        Quero assinar
                      </Button>
                    </Grid>
                  </Box>
                </Grid>

                {/* Mobile */}
                <Grid item xs={2} md={12}>
                  <Box display={{ xs: 'flex', sm: 'flex', md: 'none' }}>
                    <TopBarMobile />
                  </Box>
                </Grid>

              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>

  );
}