import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography
} from '@material-ui/core';
import TopBarSecondary from '../components/TopBarSecondary';
import Footer from '../components/Footer';


const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   section: {
      width: '100%',
      height: '760px',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left top',
      [theme.breakpoints.down('sm')]: {

      },
   },
   container: {
      paddingTop: '150px',
      position: 'relative',
      zIndex: '1',
   },
   classSubTitle: {
      color: theme.palette.primary.dark,
      fontFamily: 'Lato, sans-serif',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
   },
   classDescription: {
      color: theme.palette.common.main,
   },
   classImage: {
      marginLeft: '7rem',
      width: '554px',
      [theme.breakpoints.down('sm')]: {
         marginLeft: '0',
      }
   },
   paragrafo: {
      marginBottom: '1rem',
      color: theme.palette.primary.contrastText,
   },
   dark: {
      marginTop: '3rem',
      marginBottom: '1rem',
      color: theme.palette.common.dark,
   },
}));

export default function Home() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBarSecondary />

         {/* Como podemos te ajudar? */}
         <Box px={{ xs: 4, md: 0 }} mb={{ xs: 8 , md: 15 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='center'>
                  <Grid item md={8}>
                     <Typography variant='h1' component='h2'>
                        <Box mb={2} className={classes.classSubTitle}>
                           Nossa política
                        </Box>
                     </Typography>
                     <Typography variant='h1'>
                        <Box mb={{ xs: 6, md: 0 }} className={classes.classTitle}>
                           Política de privacidade
                        </Box>
                     </Typography>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Box px={{ xs: 4, md: 0 }} mb={25}>
            <Container>

               <Grid container justifyContent='center' spacing={8}>
                  <Grid item md={8}>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility se preocupa com a sua privacidade e respeita a proteção de seus dados. O cumprimento da legislação relativa a proteção de dados apresenta-se como uma prioridade, o que se reafirma com a presente política da privacidade.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility adota todas as medidas necessárias para assegurar que os dados pessoais de terceiros que estão sob sua guarda sejam tratados de forma segura e de acordo com a legislação aplicável.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Esta política de privacidade online descreve quais tipos de informações coletamos por meio de nosso website, como essas informações são tratadas e com quem são compartilhadas, visando a total transparência entre A Click Mobility e você, internauta/usuário(a).
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        1. Considerações iniciais
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility, por meio deste website, oferece aos seus visitantes uma gama de serviços, informações e ofertas de produtos e serviços. Para ter acesso a esses serviços ou ofertas, ou, ainda, no intuito de conhecer suas opiniões ou simplesmente melhorar a experiência de navegação do visitante deste website, A Click Mobility sugere o cadastramento do usuário/internauta através de formulários de website. Ao fornecer informações cadastrais e preferencias nestes formulários, incluindo dados pessoais e, consentindo com o uso destes destas informações através de campo check-box (doravante denominado “opt-in”), lembre-se de que você está autorizando A Click Mobility e os seus parceiros de negócio listados no item 6 desta Política a utilizar os seus dados para as finalidades descritas nesses consentimentos e em conformidade com esta Política de Privacidade.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Política de Privacidade do uso deste website, assim como a utilização, proteção e destinação das informações encontram-se descritas a seguir. Caso você não concorde com os termos deste instrumento, não prossiga com o preenchimento do campo check-box de consentimento e, principalmente, NÃO FORNEÇA E NÃO AUTORIZE O USO, TRATAMENTO E COMPARTILHAMENTO das informações que lhes forem solicitadas nos formulários. Saiba também que, a qualquer momento após fornecer os dados ou consentido com seu uso, você poderá solicitar a retirada dos seus dados de nosso banco de dados, ou retirar o seu consentimento para utilização dos seus dados.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Todas as informações solicitadas neste website e fornecidas por você serão tratadas como confidenciais e poderão ser utilizadas pelA Click Mobility, outras empresas do mesmo grupo econômico, Concessionárias Click Mobility, e pelos parceiros de negócio descritos no item 6 desta Política de Privacidade, respeitando as disposições legais aplicáveis, com o objetivo principal de melhor adequar os nossos produtos e serviços ao perfil de seus usuários.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility mantém sistemas de segurança para proteção contra invasões, hackers, crackers e outros, seguindo os melhores padrões de mercado e estará constantemente verificando os seus níveis de segurança. A Click Mobility esclarece que suas informações poderão ser utilizadas para cumprimento de obrigações legais e/ou regulatórias, quando solicitadas pela administração pública ou em procedimento judicial/extrajudicial, quando necessário para atender aos interesses do titular dos dados ou para sua proteção pessoal, para contato sobre pesquisas de satisfação para verificarmos a qualidade do atendimento e serviços prestados, para outros fins previstos na legislação ou, ainda, em outros casos, mediante autorização prévia do titular dos dados.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        2. Quais informações coletamos?
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Em nosso website, coletamos apenas as informações fornecidas por você através das opções: “agende um teste drive”, “quero um contato”, “fale conosco”, “chatbot” (solução de interação com o usuário via comunicação remota, utilizando computadores ligados à internet e/ou sistemas de comunicação digital, por meio de mensagens, áudio ou vídeo, em tempo real) e outras opções, por meio de formulários ou e-mails enviados pelo website. Ou seja, além dos conteúdos das mensagens também coletamos as informações cadastrais como nome, e-mail, modelo de carro de preferência, telefone para contato, CPF, preenchidos por você. As informações são recolhidas para responder às suas dúvidas/informações/reclamações/sugestões e necessidades, como envio de newsletter Click Mobility, programa de relacionamento, agendamento de teste drive e demais produtos ou serviços ofertados.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        QUANDO VOCÊ FORNECE INFORMAÇÕES CADASTRAIS E/OU PESSOAIS NESTE SITE, VOCÊ ESTÁ AUTORIZANDO A Click Mobility A UTILIZAR SUAS INFORMAÇÕES EM CONFORMIDADE COM ESTA POLÍTICA DE PRIVACIDADE.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A veracidade dos dados fornecidos é de suma importância e depende de você. Verifique seus dados antes de nos enviar, pois será por meio destes que A Click Mobility e/ou empresas do mesmo grupo econômico, Concessionárias Click Mobility, e pelos parceiros de negócio descritos no item 6 desta Política de Privacidade o contatará. Assim, você é o responsável pelas declarações prestadas e que vierem a causar prejuízos a si mesmo, à Click Mobility e/ou a terceiros.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        3. Cookies
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Durante sua visita em nosso site, A Click Mobility pode recolher por meio de cookies informação sobre você. O “cookie” não nos permite identificá-lo pessoalmente, mas de forma geral, ele permite gravar informações relativas à navegação do seu computador em nosso site a fim de que possamos te proporcionar uma melhor experiência, bem como ofertar produtos e serviços que sejam do seu interesse.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Lembrando que você pode escolher não aceitar os cookies em suas configurações de navegador, nas preferências de cookies. Mas sem eles, alertamos que a funcionalidade dos serviços oferecidos em nosso site pode ser afetada.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        4. Outras Aplicações
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Lembre-se que ao acessar plataformas e aplicativos de terceiros por meio do website dA Click Mobility (ex. links do Facebook, Twitter, Youtube, Instagram e outros), você passa a se submeter à política de privacidade da aplicação acessada, de modo que a presente política não se aplica aos dados pessoais coletados ou por você compartilhados nessas aplicações.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        5. Quem é responsável pelo Tratamento dos dados pessoais?
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        As informações que forem comunicadas por meio deste website serão tratadas como confidenciais e poderão ser utilizadas pelA Click Mobility e compartilhadas livremente com as empresas do mesmo grupo econômico, Concessionárias Click Mobility, e pelos parceiros de negócio descritos no item 6 desta Política de Privacidade, respeitando as disposições legais aplicáveis, com o objetivo principal de melhor adequar os nossos produtos e serviços ao perfil de seus usuários.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Desta forma, A Click Mobility declara que seus dados pessoais serão tratados prioritariamente pelA Click Mobility e, em casos específicos, pelas Concessionárias da Rede de Concessionários Click Mobility e/ou empresas do mesmo grupo econômico, Concessionárias Click Mobility, e pelos parceiros de negócio descritos no item 6 desta Política de Privacidade.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        6. Como utilizamos os seus dados pessoais?
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        As informações compartilhas com A Click Mobility são indispensáveis para fornecer as respostas solicitadas por você por meio de nosso website.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility garante que coleta apenas os dados pessoais estritamente necessários para prosseguir com o tratamento dos dados em conformidade com a finalidade veiculada. Além disso, você tem o direito de escolher compartilhar ou não seus dados pessoais com A Click Mobility, contudo, informamos que em nossos formulários, estão indicadas informações obrigatórias para utilização dos serviços em nosso website.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A informação é obrigatória, pois é indispensável para a execução do contrato ou para responder a uma solicitação, ou também para cumprirmos com as nossas obrigações legais. Portanto, caso não concorde em compartilhar as informações obrigatórias, não será possível responder às suas solicitações.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Ademais, caso você não concorde com os termos deste instrumento, não prossiga e, principalmente, NÃO FORNEÇA E NÃO AUTORIZE O TRATAMENTO E/OU COMPARTILHAMENTO das informações que lhes forem solicitadas. Todas as informações solicitadas neste website serão utilizadas exclusivamente pelA Click Mobility.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Em telas de cadastro existirá um campo perguntando ao cliente se concorda ou não em receber informações sobre A Click Mobility, seus produtos e serviços. Caso você não queira autorizar o envio de e-mail para seu endereço eletrônico com essas informações, deverá responder negativamente a pergunta formulada. Se você não proceder desta forma, A Click Mobility estará autorizada oficialmente a utilizar esta forma de comunicação.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility mantém sistemas de segurança física e lógica para proteção contra invasões, hackers, crackers e outros, seguindo os melhores padrões de mercado e estará constantemente verificando o nível de segurança destes. A Click Mobility esclarece que suas informações poderão ser utilizadas para contato sobre pesquisas de satisfação para verificarmos a qualidade do atendimento e serviços prestados.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        As informações que você compartilhar, em especial aquelas referentes ao cadastro e a pesquisa de perfil, serão utilizadas para oferecer produtos e serviços adaptados às suas preferências por meio das comunicações de marketing, e somente serão utilizadas para essa finalidade mediante seu consentimento prévio.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        7. É possível revogar o consentimento fornecido anteriormente?
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility informa que o consentimento fornecido por você pode ser revogado, a qualquer momento, mediante solicitação.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        O titular poderá retirar os consentimentos prestados a qualquer momento, sem que, no entanto, torne ilegítimo o tratamento de dados realizado com base nesse consentimento até a data em que os mesmos sejam retirados.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        8. Durante quanto tempo mantemos os seus dados pessoais?
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility não mantém seus dados pessoais além do tempo necessário para cumprimento da finalidade para o qual foi coletado, conforme determina a legislação aplicável.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Para determinar o prazo de conservação dos seus dados pessoais utilizamos os seguintes critérios:<br />
                        - O tempo necessário para responder à sua solicitação;<br />
                        - O período de tempo em que a sua conta de usuário estiver ativa;<br />
                        - A sua reação às nossas comunicações de marketing - tendo em vista a necessidade de se manter um registo de suas interações conosco, para efeito de gestão da nossa relação comercial;<br />
                        - As nossas obrigações legais;<br />
                        - O prazo de 5 anos para dados coletados através de cookies.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        9. Quem pode acessar os seus dados pessoais?
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Seus dados pessoais serão compartilhados com as Concessionárias Click Mobility somente para as seguintes finalidades:<br />
                        - Proceder ao agendamento de teste-drivers;<br />
                        - Enviar-lhe propostas de financiamento;<br />
                        - Enviar-lhe informações sobre acessórios e serviços;<br />
                        - Enviar-lhe informações gerais.<br />
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        O tratamento dos seus dados pessoais pelas Concessionárias Click Mobility para as finalidades indicadas será efetuado de acordo com essa política de privacidade e A Click Mobility recomendará, em momento oportuno, que as Concessionários internalizem esta política de privacidade em suas próprias políticas.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Desde logo informamos que A Click Mobility e suas Concessionárias são pessoas jurídicas distintas e, conforme legislação que rege a relação de ambas, A Click Mobility não tem qualquer ingerência sobre as ações das Concessionárias, de modo que recomendaremos a internalização desta política conforme mencionado, sem, contudo, podermos obrigá-las a tal internalização.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Além do compartilhamento com as Concessionárias, é possível o compartilhamento de dados com o Banco RCI, a fim de ofertar-lhe produtos e serviços que sejam de seu interesse, desde que mediante autorização prévia ou solicitação do titular dos dados.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility informa também que o armazenamento dos seus dados pessoais ocorre principalmente em servidores localizados no Brasil.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        10. Autoridade Pública
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility somente disponibilizará seus dados às Autoridades Públicas quando a requisição para tanto for feita nos moldes da legislação. Não efetuamos essa entrega de forma excessiva, ou quando não estiver em conformidade com as determinações legais.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        11. Menores de idade
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Este website e demais canais oficiais de comunicação mantidos pelA Click Mobility não coletam informações pessoais de ou sobre qualquer pessoa menor de idade, sendo necessário ter 18 anos ou mais para cadastro e interações.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        12. Os seus direitos
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A Click Mobility permite, por parte de cliente e usuários, todos os direitos relativos ao tratamento de seus dados pessoais previstos na legislação, principalmente os seguintes:
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        - Solicitar a confirmação da existência de tratamento e o acesso aos dados que A Click Mobility possui sobre você;<br />
                        - Solicitar a retificação ou atualização dos dados pessoais inexatos ou desatualizados;<br />
                        - Solicitar a exclusão de suas informações pessoais existentes em nosso banco de dados e dos nossos parceiros, exceto nas hipóteses em que esse direito possa ser limitado conforme determina a lei;<br />
                        - Solicitar a portabilidade de seus dados pessoais num formato estruturado, de uso corrente e de leitura automática, a outro fornecedor de serviço ou produto, mediante sua solicitação;<br />
                        - Retirar, a qualquer momento, o seu consentimento, para os fins para os quais este foi obtido.<br />
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Se você deseja exercer algum dos seus direitos em relação às suas informações pessoais ou se tiver alguma dúvida sobre como usamos suas informações pessoais, entre em contato conosco por meio da área de Contato deste website ou por meio do Serviço de Atendimento ao Cliente Click Mobility.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        13. Legislação Aplicável
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A legislação aplicável para dirimir e resolver qualquer eventual litígio decorrente do uso ou má interpretação deste website será a legislação Brasileira.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        14. Foro
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Fica eleito o foro da comarca do Rio de Janeiro, Estado do Rio de Janeiro, para dirimir e resolver eventuais demandas judiciais decorrentes do uso, má interpretação deste website.
                     </Typography>
                     <Typography component='h3' variant='h3' className={classes.dark}>
                        15. Atualização desta política de privacidade
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        A presente política de privacidade está sujeita a alterações regulares para garantir que esteja atualizada com o uso de suas informações pessoais e em conformidade com as leis de proteção de dados aplicáveis.
                     </Typography>
                     <Typography component='p' variant='body1' className={classes.paragrafo}>
                        Reservamo-nos o direito de revisar esta política de privacidade, a qualquer tempo, seja pela utilização de novas tecnologias ou sempre que A Click Mobility entender necessário. A política de privacidade atualizada será publicada em nosso website, motivo pelos qual recomendamos periodicamente verifica-la.
                     </Typography>
                  </Grid>
               </Grid>

            </Container>
         </Box>

         <Footer />

      </Grid>
   );
}