import React from 'react';
import { makeStyles, Typography, Container, Grid, Box, Button } from '@material-ui/core';

import Revolucione from '../images/revolucione.webp';

const useStyles = makeStyles((theme) => ({
   container: {
      zIndex: '1',
      position: 'relative',
      borderRadius: '35px',
      overflow: 'hidden',
      padding: '0',
      marginBottom: '5rem',
      backgroundColor: theme.palette.primary.dark,
      [theme.breakpoints.down('sm')]: {
         minHeight: '300px',
         maxHeight: '100%',
         padding: '1.25rem 0.75rem',
      },
   },
   gridContainer: {
      minHeight: '370px',
      maxHeight: '440px',
   },
   bg: {
      position: 'absolute',
      left: '1rem',
      bottom: '0',
      width: 'auto',
      height: '100%',
      zIndex: '-1',
      opacity: '0.75',
   },
   icon: {
      float: 'left',
      fontSize: '2rem',
      margin: '-7px 5px 0 0',
      color: theme.palette.secondary.main,
   },
   classDescription: {
      fontWeight: '600',
      color: theme.palette.common.white,
   },
   secondaryDark: {
      color: theme.palette.secondary.dark,
   },
   light: {
      color: theme.palette.common.light,
   },
   white: {
      color: theme.palette.common.white,
   },
   buttonOutlined: {
      width: '100%',
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '600',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.common.white,
      backgroundColor: 'transparent',
      '&:hover': {
         color: theme.palette.primary.dark,
         backgroundColor: theme.palette.common.white,
      },
   },
   buttonLabel: {
      textTransform: 'none',
   },
}));

function Header() {
   const classes = useStyles();
   return (
      <Container className={classes.container}>
         <Box px={{ xs: 4, md: 24 }}>
            <Grid container justifyContent='space-between' alignItems='center' alignContent='center' className={classes.gridContainer}>
               <Grid item md='4'>
                  <Box py={{ xs: 4, md: 18 }} className={classes.white}>
                     <Box mb={8}>
                        <Typography variant='h2' component='h4'>
                           Revolucione sua relação com um carro.
                        </Typography>
                     </Box>
                     <Box mb={8}>
                        <Typography variant='body1' component='p'>
                           Imagine ter o carro que você quer, personalizado
                           do seu jeito, sem custos e burocracias de impostos, taxas e manutenção.
                        </Typography>
                     </Box>
                     <Grid item md={9}>
                        <Button color='secondary' variant='outlined' href='https://assinatura.meuclickmobility.com.br'
                           classes={{
                              root: classes.buttonOutlined,
                              label: classes.buttonLabel,
                           }}>Assine agora mesmo
                        </Button>
                     </Grid>
                  </Box>
               </Grid>
               <Grid item md='7'>
                  <Box py={{ xs: 4, md: 12 }} pr={{ xs: 6, md: 0 }}>
                     <img src={Revolucione} alt='Click Mobility' />
                  </Box>
               </Grid>
            </Grid>
         </Box>
      </Container>
   );
}

export default Header;