import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography
} from '@material-ui/core';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import ConhecaFrota from '../components/ConhecaFrota';
import Depoimentos from '../components/Depoimentos';
import Revolucione from '../components/Revolucione';

// Icons
import { Icon } from '@iconify/react';

// Images
import ImageHeader from '../images/sobre-nos-header.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   section: {
      width: '100%',
      height: '760px',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left top',
      backgroundColor: theme.palette.common.light,
      [theme.breakpoints.down('sm')]: {
         height: '100%',
      },
   },
   container: {
      paddingTop: '96px',
      position: 'relative',
      zIndex: '1',
   },
   classSubTitle: {
      color: theme.palette.primary.dark,
      fontFamily: 'Lato, sans-serif',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
   },
   classDescription: {
      color: theme.palette.common.main,
   },
   classImage: {
      marginLeft: '7rem',
      width: '554px',
      [theme.breakpoints.down('sm')]: {
         marginLeft: '0',
         width: '100%',
      }
   },
   buttonLabel: {
      textTransform: 'none',
   },
   button: {
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '800',
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
         backgroundColor: theme.palette.secondary.main,
      },
   },
   iconHeader: {
      float: 'left',
      fontSize: '1.5rem',
      margin: '-0.25rem 0.5rem 0 0',
      color: theme.palette.primary.dark,
   },
   icon: {
      fontSize: '1.5rem',
      margin: '0 0.3rem',
      color: theme.palette.primary.dark,
   },
   iconBg: {
      backgroundColor: theme.palette.primary.light,
      padding: '6px 1px 0px 1px',
      marginTop: '3px',
      borderRadius: '8px',
      display: 'inline-block',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '1rem',
      }
   },
}));

export default function Home() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBar />

         {/* Bem mais que um carro */}
         <Grid className={classes.section}>
            <Container className={classes.container}>
               <Box py={{ xs: 4, md: 4 }} pt={{ xs: 4, md: 24 }}>
                  <Grid container direction='row' justifyContent={{ xs: 'center', md: 'space-between' }} alignItems='center'>
                     <Grid item md='6' className={classes.classBoxText}>
                        <Box mt={{ xs: 6, md: -28 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 0 }}>
                           <Typography variant='h1' component='h2'>
                              <Box mb={2} className={classes.classSubTitle}>
                                 Sobre nós
                              </Box>
                           </Typography>
                           <Typography variant='h1'>
                              <Box mb={6} className={classes.classTitle}>
                                 Bem mais que um carro, conforto e segurança ao seu dispor.
                              </Box>
                           </Typography>
                           <Typography variant='h5'>
                              <Box mb={{ xs: 12 }} className={classes.classDescription}>
                                 A Click Mobility é uma empresa de carro por assinatura (PF) e terceirização de frota (PJ) que faz parte
                                 do Grupo LeAuto. Desde 1969, o grupo LeAuto possui uma enorme atuação no segmento de concessionárias
                                 de automóveis e é um dos maiores grupos do Rio de Janeiro, com 12 concessionárias.
                              </Box>
                           </Typography>
                           <Box mb={{ xs: 12 }} className={classes.classDescription}>
                              <Icon icon="bi:arrow-down" className={classes.iconHeader} />
                              <Typography component='span' variant='body2'>
                                 Continue explorando
                              </Typography>
                           </Box>
                        </Box>
                     </Grid>
                     <Grid item md='6' className={classes.classBoxImage}>
                        <Box mt={{ xs: 8, md: 0 }} px={{ xs: 4, md: 0 }} pr={{ xs: 0, md: 0 }}>
                           <img src={ImageHeader} alt='Click Mobility' className={classes.classImage} width='100%' />
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Grid>

         {/* Somos uma Locadora... */}
         <Box py={30} px={{ xs: 4, md: 0 }}>
            <Container>
               <Grid container justifyContent='space-between' alignItems='flex-start'>
                  <Grid item md={1}>
                     <Box className={classes.iconBg}>
                        <Icon icon="ph:navigation-arrow" className={classes.icon} />
                     </Box>
                  </Grid>
                  <Grid item md={5}>
                     <Box mb={12}>
                        <Typography component='h2' variant='h2' className={classes.dark}>
                           {/* Somos uma Locadora com o serviço de carro por assinatura com valor fixo mensal. */}
                           A Click Mobility tem planos especiais e diferenciados para sua empresa, dê um click e confira as opções.
                        </Typography>
                     </Box>
                     <Box mb={6}>
                        <Grid container justifyContent='flex-start' alignItems='flex-start'>
                           <Grid item md={6}>
                              <Grid container justifyContent='flex-start' spacing={8} alignItems='flex-start'>
                                 <Grid item>
                                    <Box className={classes.iconBg}>
                                       <Icon icon="ph:currency-circle-dollar-bold" className={classes.icon} />
                                    </Box>
                                 </Grid>
                                 <Grid item>
                                    <Typography component='h5' variant='body1' className={classes.dark}>
                                       O melhor custo <br />benefício
                                    </Typography>
                                 </Grid>
                              </Grid>
                              <Grid container justifyContent='flex-start' spacing={8} alignItems='flex-start'>
                                 <Grid item>
                                    <Box className={classes.iconBg}>
                                       <Icon icon="uil:shield-check" className={classes.icon} />
                                    </Box>
                                 </Grid>
                                 <Grid item>
                                    <Typography component='h5' variant='body1' className={classes.dark}>
                                       Segurança e <br />liberdade
                                    </Typography>
                                 </Grid>
                              </Grid>
                           </Grid>
                           <Grid item md={6}>
                              <Grid container justifyContent='flex-start' spacing={8} alignItems='flex-start'>
                                 <Grid item>
                                    <Box className={classes.iconBg}>
                                       <Icon icon="carbon:flash" className={classes.icon} />
                                    </Box>
                                 </Grid>
                                 <Grid item>
                                    <Typography component='h5' variant='body1' className={classes.dark}>
                                       Agilidade e praticidade <br />na contratação
                                    </Typography>
                                 </Grid>
                              </Grid>
                              <Grid container justifyContent='flex-start' spacing={8} alignItems='flex-start'>
                                 <Grid item>
                                    <Box className={classes.iconBg}>
                                       <Icon icon="bx:like" className={classes.icon} />
                                    </Box>
                                 </Grid>
                                 <Grid item>
                                    <Box mt={2}>
                                       <Typography component='h5' variant='body1' className={classes.dark}>
                                          Conforto
                                       </Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Box>
                  </Grid>
                  <Grid item md={1}></Grid>
                  <Grid item md={4}>
                     <Box mb={6}>
                        <Typography component='p' variant='body1' className={classes.contrastText}>
                           Qual conceito da Click Mobility? A Click Mobility é uma locadora com o serviço de carro
                           por assinatura e terceirização de frota com valor fixo mensal. Você paga uma parcela
                           por mês e dirige um 0KM por 12, 24, 36 ou 48 meses sem se preocupar com
                           documentação, IPVA, Licenciamento, Proteção/Seguro, com manutenções previstas
                           manual e com a desvalorização do veículo.
                        </Typography>
                     </Box>
                     <Box mb={6}>
                        <Typography component='p' variant='body1' className={classes.contrastText}>
                           Além de escolher o pacote de km mensal ideal para você (1.000 a 3.000KM), você
                           também pode contratar veículo reserva, leva e traz nas manutenções, serviço
                           concierge e até alguns veículos podem ser blindados, conforme observados os
                           possíveis prazos e os modelos para esta finalidade.
                        </Typography>
                     </Box>
                     <Typography component='p' variant='body1' className={classes.contrastText}>
                        É você na Click Mobility sem ter preocupações e de carro novo com proteção,
                        reboque e documentação inclusa. #MeuClickMobility
                     </Typography>
                  </Grid>
                  <Grid item md={1}></Grid>
               </Grid>
            </Container>
         </Box>

         <Depoimentos />
         <Box mt={-16}>
            <Revolucione />
         </Box>
         <ConhecaFrota />
         <Footer />

      </Grid>
   );
}