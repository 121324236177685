import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography,
   List, ListItem
} from '@material-ui/core';
import TopBarSecondary from '../components/TopBarSecondary';
import Footer from '../components/Footer';

// Icons
import { Icon } from '@iconify/react';

// Slide
import Slider from 'react-slick';
import '../services/slick.css';
import '../services/slick-theme.css';

// Images
import Argo1 from '../images/argo-1.webp';
import Argo2 from '../images/argo-2.webp';
import Argo3 from '../images/argo-3.webp';
import Argo4 from '../images/argo-4.webp';
import Argo5 from '../images/argo-5.webp';
import Cronos1 from '../images/cronos-1.webp';
import Cronos2 from '../images/cronos-2.webp';
import Cronos3 from '../images/cronos-3.webp';
import Cronos4 from '../images/cronos-4.webp';
import Cronos5 from '../images/cronos-5.webp';
import Renegade1 from '../images/renegade-1.webp';
import Renegade2 from '../images/renegade-2.webp';
import Renegade3 from '../images/renegade-3.webp';
import Renegade4 from '../images/renegade-4.webp';
import Renegade5 from '../images/renegade-5.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   section: {
      width: '100%',
      height: '760px',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left top',
      [theme.breakpoints.down('sm')]: {

      },
   },
   container: {
      paddingTop: '150px',
      position: 'relative',
      zIndex: '1',
   },
   classSubTitle: {
      color: theme.palette.primary.dark,
      fontFamily: 'Lato, sans-serif',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
   },
   classDescription: {
      color: theme.palette.common.main,
   },
   classImage: {
      marginLeft: '7rem',
      width: '554px',
      [theme.breakpoints.down('sm')]: {
         marginLeft: '0',
      }
   },
   buttonLabel: {
      textTransform: 'none',
   },
   button: {
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '800',
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
         backgroundColor: theme.palette.secondary.main,
      },
   },
   iconHeader: {
      float: 'left',
      fontSize: '1.5rem',
      margin: '-0.25rem 0.5rem 0 0',
      color: theme.palette.primary.dark,
   },
   icon: {
      float: 'left',
      fontSize: '1rem',
      margin: '0.25rem 1rem 0 0',
      color: theme.palette.primary.dark,
   },
   iconBg: {
      backgroundColor: theme.palette.primary.light,
      padding: '6px 1px 0px 1px',
      marginTop: '3px',
      borderRadius: '8px',
      display: 'inline-block',
   },
   dark: {
      color: theme.palette.common.dark,
   },
   list: {
      padding: '0',
      marginBottom: '0',
   },
   listItem: {
      padding: '0',
      marginBottom: '0.4rem',
   },
   box: {
      borderRadius: '8px',
      border: '1px solid rgba(111, 111, 120, 0.1)',
      marginBottom: '2rem',
   },
   boxText: {
      padding: '1rem 2rem',
   },
}));

export default function Home() {
   const classes = useStyles();

   // Slide
   var settings = {
      infinite: true,
      speed: 1000,
      fade: true,
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: true,
      dots: true,
      responsive: [
         {
            breakpoint: 720,
            settings: {

            }
         },
         {
            breakpoint: 480,
            settings: {

            }
         }
      ]
   };

   return (
      <Grid id='frota' className={classes.root}>

         <TopBarSecondary />

         {/* Conheça todos os carros */}
         <Box mb={{ xs: 12, md: 30 }} px={4}>
            <Container className={classes.container}>
               <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item md={5}>
                     <Typography variant='h1' component='h2'>
                        <Box mb={2} className={classes.classSubTitle}>
                           Nossa Frota
                        </Box>
                     </Typography>
                     <Typography variant='h1'>
                        <Box mb={{ xs: 6, md: 0 }} className={classes.classTitle}>
                           O Carro perfeito para você está aqui.
                        </Box>
                     </Typography>
                  </Grid>
                  <Grid item md={4}>
                     <Typography variant='h5'>
                        <Box mb={{ xs: 12, md: 0 }} pt={{ xs: 0, md: 8 }} className={classes.classDescription}>
                           A correria do seu dia a dia e os seus momentos de lazer não serão mais os mesmos. 
                           Dê um toque de conforto, ágilidade e segurança aos momentos mais importantes da sua vida com apenas um Click.
                        </Box>
                     </Typography>
                  </Grid>
                  <Grid item md={2}>
                     <Box mb={{ xs: 12, md: 0 }} pt={{ xs: 0, md: 8 }} className={classes.classDescription}>
                        <Icon icon="bi:arrow-down" className={classes.iconHeader} />
                        <Typography component='span' variant='body2'>
                           Veja mais detalhes
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Container>

            {/* Fiat Argo */}
            <Grid container justifyContent='space-between' alignItems='center' className={classes.box}>
               <Grid item md={5}>
                  <Slider {...settings}>
                     <Box><img src={Argo1} width='100%' alt='Click Mobility - Fiat Argo 2022' /></Box>
                     <Box><img src={Argo2} width='100%' alt='Click Mobility - Fiat Argo 2022' /></Box>
                     <Box><img src={Argo3} width='100%' alt='Click Mobility - Fiat Argo 2022' /></Box>
                     <Box><img src={Argo4} width='100%' alt='Click Mobility - Fiat Argo 2022' /></Box>
                     <Box><img src={Argo5} width='100%' alt='Click Mobility - Fiat Argo 2022' /></Box>
                  </Slider>
               </Grid>
               <Grid item md={6} className={classes.boxText}>
                  <Box mb={{ xs: 2, md: 2 }} className={classes.dark}>
                     <Typography component='h5' variant='h2'>
                        Fiat Argo
                     </Typography>
                  </Box>
                  <Box mb={{ xs: 8, md: 8 }} className={classes.dark}>
                     <Typography component='p' variant='body2' className={classes.contrastText}>
                        Veja todos os diferenciais deste carro.
                     </Typography>
                  </Box>
                  <Grid container justifyContent='space-between'>
                     <Grid item md={6}>
                        <List className={classes.list}>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>4 portas</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Vidro elétrico</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Air bag</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>ABS</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Câmbio manual</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                        </List>
                     </Grid>
                     <Grid item md={6}>
                        <List className={classes.list}>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Combustível Flex</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Trava elétrica</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Direção elétrica</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Porta-malas de 300L</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>5 ocupantes</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                        </List>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>

            {/* Fiat Cronos */}
            <Grid container justifyContent='space-between' alignItems='center' className={classes.box}>
               <Grid item md={5}>
                  <Slider {...settings}>
                     <Box><img src={Cronos1} width='100%' alt='Click Mobility - Fiat Cronos 2022' /></Box>
                     <Box><img src={Cronos2} width='100%' alt='Click Mobility - Fiat Cronos 2022' /></Box>
                     <Box><img src={Cronos3} width='100%' alt='Click Mobility - Fiat Cronos 2022' /></Box>
                     <Box><img src={Cronos4} width='100%' alt='Click Mobility - Fiat Cronos 2022' /></Box>
                     <Box><img src={Cronos5} width='100%' alt='Click Mobility - Fiat Cronos 2022' /></Box>
                  </Slider>
               </Grid>
               <Grid item md={6} className={classes.boxText}>
                  <Box mb={{ xs: 2, md: 2 }} className={classes.dark}>
                     <Typography component='h5' variant='h2'>
                        Fiat Cronos
                     </Typography>
                  </Box>
                  <Box mb={{ xs: 8, md: 8 }} className={classes.dark}>
                     <Typography component='p' variant='body2' className={classes.contrastText}>
                        Veja todos os diferenciais deste carro.
                     </Typography>
                  </Box>
                  <Grid container justifyContent='space-between'>
                     <Grid item md={6}>
                        <List className={classes.list}>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>4 portas</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Vidro elétrico</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Air bag</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>ABS</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Câmbio manual</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                        </List>
                     </Grid>
                     <Grid item md={6}>
                        <List className={classes.list}>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Combustível Flex</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Trava elétrica</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Direção elétrica</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Porta-malas de 525L</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>5 ocupantes</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                        </List>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>

            {/* Jeep Renegade */}
            <Grid container justifyContent='space-between' alignItems='center' className={classes.box}>
               <Grid item md={5}>
                  <Slider {...settings}>
                     <Box><img src={Renegade1} width='100%' alt='Click Mobility - Jeep Renegade 2022' /></Box>
                     <Box><img src={Renegade2} width='100%' alt='Click Mobility - Jeep Renegade 2022' /></Box>
                     <Box><img src={Renegade3} width='100%' alt='Click Mobility - Jeep Renegade 2022' /></Box>
                     <Box><img src={Renegade4} width='100%' alt='Click Mobility - Jeep Renegade 2022' /></Box>
                     <Box><img src={Renegade5} width='100%' alt='Click Mobility - Jeep Renegade 2022' /></Box>
                  </Slider>
               </Grid>
               <Grid item md={6} className={classes.boxText}>
                  <Box mb={{ xs: 2, md: 2 }} className={classes.dark}>
                     <Typography component='h5' variant='h2'>
                        Jeep Renegade
                     </Typography>
                  </Box>
                  <Box mb={{ xs: 8, md: 8 }} className={classes.dark}>
                     <Typography component='p' variant='body2' className={classes.contrastText}>
                        Veja todos os diferenciais deste carro.
                     </Typography>
                  </Box>
                  <Grid container justifyContent='space-between'>
                     <Grid item md={6}>
                        <List className={classes.list}>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>4 portas</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Vidro elétrico</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Air bag</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>ABS</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Câmbio automático</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                        </List>
                     </Grid>
                     <Grid item md={6}>
                        <List className={classes.list}>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Combustível Flex</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Trava elétrica</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Direção elétrica</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>Porta-malas de 320L</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                           <ListItem className={classes.listItem}>
                              <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                 <Grid item md={12}>
                                    <Box mb={1}>
                                       <Icon icon="fa6-regular:circle-check" className={classes.icon} />
                                       <Typography component='span' variant='body1' className={classes.contrastText}>5 ocupantes</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </ListItem>
                        </List>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
            
         </Container>

         <Footer />

      </Grid>
   );
}