import React from 'react';
import {
   makeStyles, Grid
} from '@material-ui/core';
import TopBar from '../components/TopBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
// import Modelos from '../components/Modelos';
import Calculadora from '../components/Calculadora';
import CincoClicks from '../components/CincoClicks';
import BemMais from '../components/BemMais';
import ConhecaFrota from '../components/ConhecaFrota';

// Images
import ImageHeader from '../images/header.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   classSubTitle: {
      color: theme.palette.common.main,
      fontWeight: '600',
   },
   classDescription: {
      color: theme.palette.common.main,
   },
   classImage: {
      marginLeft: '7rem',
      [theme.breakpoints.down('sm')]: {
         marginLeft: '0',
      }
   },
   classButton: {
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '800',
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
         backgroundColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.down('sm')]: {
         fontSize: '0.875rem',
         fontWeight: '600',
         padding: '0.65rem 2rem',
      }
   },
}));

export default function Home() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBar />
         
         <Header
            subtitle='Click Mobility'
            classSubTitle={classes.classSubTitle}
            title='Carro por assinatura'
            classTitle={classes.classTitle}
            description='Seu carro ZERO KM do seu jeito, sem custos e burocracia de impostos, taxas e manutenção.'
            classDescription={classes.classDescription}
            image={ImageHeader}
            imageAlt='Click Mobility'
            classBoxImage={classes.classBoxImage}
            classImage={classes.classImage}
            buttonLabel='Assinar agora!'
            classButton={classes.classButton}
            linkButton='https://assinatura.meuclickmobility.com.br'
         />
         <Calculadora />
         {/* <Modelos /> */}
         <ConhecaFrota />
         <BemMais />
         <CincoClicks />
         <Footer />

      </Grid>
   );
}